<template>
  <u-btn color="white" size="sm" class="app-btn text-blue-grey-5" no-caps>
    Todas as contas
    <u-icon name="caret-down" class="m-l" type="fa" style="font-size: 14px !important;"/>
    <u-popover ref="popover" class="window-context-menu menu-profile min" anchor="bottom left" self="top left"
               :offset="[0, 5]">
      <div class="simple-menu m-t-xs">
        <div>
          <label for="filtro-contas-todas" class="no-select font-12 flex items-center">
            <input @change="selectAll" type="checkbox" :checked="allSelected" id="filtro-contas-todas">
            <span class="m-l-xs">Todas</span>
          </label>
          <label v-for="banco in bancos" @change="change" :key="banco.id" :for="'filtro-contas-' + banco.id"
                 class="no-select font-12 flex items-center m-t-sm">
            <input type="checkbox" v-model="banco.checked" :id="'filtro-contas-' + banco.id">
            <span class="m-l-xs">{{ banco.nome }}</span>
          </label>
        </div>
      </div>
    </u-popover>
  </u-btn>
</template>

<script>
import {UPopover} from "uloc-vue"
import {listBanco as list} from "@/domain/financeiro/services/banco"

const search = function () {
  let filtros = encodeURI(`&&sortBy=name&descending=false&active=true`)
  return list(50, 1, filtros)
}

export default {
  name: "FiltroConta",
  components: {
    //MenuOptionsItem,
    UPopover
  },
  mounted() {
    search()
        .then(response => {
          this.bancos = response.data.result.map(r => {
            return {
              ...r,
              checked: true
            }
          })
        })
        .catch(error => {
          this.alertApiError(error)
        })
  },
  watch: {
  },
  computed:{
    allSelected () {
      return this.bancos && this.bancos.filter(b => b.checked).length === this.bancos.length
    }
  },
  data() {
    return {
      tudo: true,
      bancos: null
    }
  },
  methods: {
    selectAll (v) {
      if (v && v.target && v.target.checked) {
        this.bancos && this.bancos.map(b => {
          b.checked = true
        })
        this.change(v)
        return
      }
      this.bancos && this.bancos.map(b => {
        b.checked = false
      })
      this.change(v)
    },
    change(v) {
      this.$nextTick(() => {
        console.log('Event change')
        this.$emit('change', this.bancos.filter(b => b.checked).map(b => b.id))
      })
    }
  }
}
</script>
