<template>
  <div class="fin-filtro-periodo">
    <u-btn color="white" size="sm" class="app-btn text-blue-grey-5" no-caps>
      <span v-if="filters.data1 && filters.data2">{{ convert(filters.data1)|formatDate('dd MMM yyyy') }} - {{ convert(filters.data2)|formatDate('dd MMM yyyy') }}</span>
      <span v-else-if="filters.dueFilter">Todas vencidas</span>
      <span v-else>Sem filtro</span>
      <u-icon name="caret-down" class="m-l" type="fa" style="font-size: 14px !important;" />
      <u-popover ref="popover" class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 5]" style="min-width: 172px">
        <div>
          <ul>
            <menu-options-item v-for="periodo in periodos" :key="periodo.id" @click="$emit('mudarPeriodo', periodo)" :label="periodo.value" close  />
          </ul>
        </div>
      </u-popover>
    </u-btn>

    <div class="periodo-btn-nav">
      <u-btn @click="$emit('addsub', 'sub')" class="app-btn text-blue-grey-5" color="white" size="sm" icon="caret-left" icon-type="fa" />
      <u-btn @click="$emit('addsub', 'add')" class="app-btn text-blue-grey-5" color="white" size="sm" icon="caret-right" icon-type="fa" style="border-left: #f2f2f2 1px solid" />
    </div>
  </div>
</template>

<script>
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
import {UPopover} from "uloc-vue"
import {datePtToEn} from "@/utils/date"
export default {
  name: "FiltroPeriodo",
  props: ['filters', 'periodos', 'stats'],
  components: {
    MenuOptionsItem,
    UPopover
  },
  methods: {
    convert (d) {
      return datePtToEn(d)
    }
  }
}
</script>
