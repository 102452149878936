<template>
  <div>
  <u-btn @click="toggle" color="white" size="sm" class="app-btn text-blue-grey-5" no-caps>
<!--    Mais filtros-->
    <u-icon name="filter" class="m-l__ m-r-sm fin-icon-filter" type="fa" /> <span>Filtros</span>
    <u-popover :anchor-click="false" disable-close-on-click-outside @show="activated" ref="popover" class="window-context-menu wrapper min popover-effect fin-filtro-mais-popover" anchor="bottom left" self="top left"
               :offset="[0, 5]">
      <div class="simple-menu m-t-xs flex justify-between column full-width e-input-modern size1">
        <e-row class="col-grow-1 m-t" mr>
          <e-col style="min-width: 170px" class="m-l m-r">
            <erp-s-field
                view="lr"
                label="Categoria:"
                label-width="90px"
            >
              <categoria-hirerarquia-select size="2" v-model="filters.categoria" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col style="min-width: 170px" class="m-l m-r">
            <erp-s-field
                view="lr"
                label="ID da transação"
                label-width="90px"
            >
              <erp-input size="2" ref="input0" v-model="filters.id" @keydown.enter="buscar" style="max-width: 100px"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="col-grow-1" mr>
          <e-col style="min-width: 170px" class="m-l m-r">
            <erp-s-field
                view="lr"
                label="ID do leilão"
                label-width="90px"
            >
              <erp-input size="2" v-model="filters.relationEntityId" @keydown.enter="buscar" style="max-width: 100px"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="col-grow-1" mr>
          <e-col style="min-width: 170px" class="m-l m-r">
            <erp-s-field
                view="lr"
                label="Alguém"
                label-width="90px"
            >
              <fin-pessoa-input @keydown.enter.native="buscar" v-model="filters.pessoa" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="col-grow-1" mr>
          <e-col style="min-width: 170px" class="m-l m-r">
            <erp-s-field
                view="lr"
                label="Processo"
                label-width="90px"
            >
              <global-processo-input v-model="filters.processo" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="col-grow-1" mr>
          <e-col style="min-width: 170px" class="m-l m-r">
            <erp-s-field
                view="lr"
                label="Centro de custo"
                label-width="90px"
            >
              <centro-custo-input @keydown.enter.native="buscar" v-model="filters.centroCusto" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="col-grow-1" mr>
          <e-col style="min-width: 170px" class="m-l m-r">
            <erp-s-field
                view="lr"
                label="Dedutível"
                label-width="90px"
            >
              <erp-select
                  :options="[
                    {label: 'Qualquer', value: null},
                    {label: 'Dedutível', value: true},
                    {label: 'Não Dedutível', value: false},
                  ]"
                  v-model="filters.dedutivel" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="col-grow-1" mr>
          <e-col style="min-width: 170px" class="m-l m-r">
            <erp-s-field
                view="lr"
                label="Pago"
                label-width="90px"
            >
              <erp-select
                  :options="[
                    {label: 'Qualquer', value: null},
                    {label: 'Sim', value: true},
                    {label: 'Não', value: false},
                  ]"
                  v-model="filters.pago" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="m-t" mr>
          <e-col class="text-right">
            <a @click="reset" class="m-r font-12 text-negative">Remover filtros</a>
            <e-btn label="Buscar" @click="buscarFechar" />
          </e-col>
        </e-row>
      </div>
    </u-popover>
  </u-btn>
  </div>
</template>

<script>
import {UPopover} from "uloc-vue"
import {ErpInput, ErpSField, ErpSelect} from "uloc-vue-plugin-erp"
import ECol from "@/components/layout/components/Col"
import ERow from "@/components/layout/components/Row"
import CentroCustoInput from "@/components/financeiro/components/input/CentroCustoInput"
import FinPessoaInput from "@/components/financeiro/components/input/PessoaInput"
import CategoriaHirerarquiaSelect from "@/components/financeiro/components/input/CategoriaHirerarquiaSelect"
import GlobalProcessoInput from "components/processos/components/processos/include/ProcessoInput"

export default {
  name: "FinFiltrosAdicionais",
  inject: ['container'],
  props: ['filters', 'stats'],
  components: {
    CategoriaHirerarquiaSelect,
    FinPessoaInput,
    CentroCustoInput,
    ERow,
    ECol,
    //MenuOptionsItem,
    UPopover,
    ErpSField,
    ErpInput,
    ErpSelect,
    GlobalProcessoInput
  },
  mounted() {
    document.body.addEventListener('click', this.clickOutside, true)
  },
  watch: {
  },
  data() {
    return {
      prevent: false
    }
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.clickOutside)
  },
  methods: {
    toggle () {
      this.$nextTick(() => {
        if (this.prevent) return
        !this.$refs.popover.showing ? this.$refs.popover.show() : this.$refs.popover.hide()
      })
    },
    activated() {
      this.$nextTick(() => {
        // this.$refs.input0.focus()
      })
    },
    buscar () {
      this.container.table.filters.data1 = null
      this.container.table.filters.data2 = null
      this.container.table.filters.search = null
      this.container.pesquisar()
    },
    buscarFechar () {
      this.container.pesquisar()
      this.$refs.popover.hide()
    },
    reset () {
      this.container.table.filters.id = null
      this.container.table.filters.relationEntityId = null
      this.container.table.filters.pessoa = null
      this.container.table.filters.centroCusto = null
      this.$refs.popover.hide()
      this.container.pesquisar()
    },
    clickOutside(evt) {
      if (
          evt && evt.target && this.$el &&
          (this.$el.contains(evt.target) || (this.$el.parentNode && this.$el.parentNode.contains(evt.target)))
      ) {
        // this.leave()
        // this.$el.classList.add('focused')
        // this.focus()
        // placeCaretAtEnd(this.$refs.editor)
        console.log('Click outside')
        if (!this.$refs.popover.showing) return
        this.prevent = true
        setTimeout(() => {
          this.prevent = false
        }, 100)
        this.$refs.popover.hide()
        return true
      }
      console.log('Click inside')
      // this.$refs.popover.show()
      // this.hide(evt)
    }
  }
}
</script>
